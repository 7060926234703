<template>
	<div class="feedback" ref="feedback">
        <div class="fixed-box fixed">
            <div class="float-box">
                <div class="float-left" @click="toQuotationDetail()">
                    <div class="basic-msg">
                        <div class="port-msg">
                            {{preOrderInfo.dep}}-{{preOrderInfo.des}}
                            <span v-if="!!preOrderInfo.volume && (!!preOrderInfo.gross_weight ||!!preOrderInfo.piece && preOrderInfo.piece!=0 ||!!preOrderDeliverDate)">：</span>
                        </div>
                        <div class="other-msg">
                            <p v-if="!!preOrderInfo.volume">{{preOrderInfo.volume}}m³</p>
                            <i class="line" v-if="!!preOrderInfo.volume && (!!preOrderInfo.gross_weight ||!!preOrderInfo.piece && preOrderInfo.piece!=0 ||!!preOrderDeliverDate)"></i>
                            <p v-if="!!preOrderInfo.gross_weight">{{preOrderInfo.gross_weight}}KG</p>
                            <i class="line" v-if="!!preOrderInfo.gross_weight && (!!preOrderInfo.piece && preOrderInfo.piece!=0 ||!!preOrderDeliverDate)"></i>
                            <p v-if="!!preOrderInfo.piece && preOrderInfo.piece!=0">{{preOrderInfo.piece}}件</p>
                            <i class="line" v-if=" !!preOrderInfo.piece && preOrderInfo.piece!=0 && !!preOrderDeliverDate"></i>
                            <p>{{preOrderDeliverDate}}</p>
                        </div>
                    </div>
                    <div class="remark" v-if="!!preOrderInfo.remark">备注：{{preOrderInfo.remark}}</div>
                </div>
                <a :href="!!preOrderInfo.custom_phone ? ('tel:' + preOrderInfo.custom_phone) : '#'">
                    <div class="float-right icondianhua2 iconfont"></div>
                </a>
            </div>
        </div>
        <van-loading  v-if="moreLoading" size="0.28rem" color="#888" style="text-align: center;"/>
        <div v-for="(item, idx) in chatMessageList" :key="idx">
            <div v-if="item.type==2">
                <div class="item-box" v-if="!!item.dep">
                    <p class="feedback-time">{{item.createdTime}}</p>
                    <div class="item item-right">
                        <van-image
                            round
                            width="0.8rem"
                            height="0.8rem"
                            :src="wx_usr.headImgUrl"
                            class="item-img"
                            @click="gotoMyPage()"
                        />
                        <div class="message-box message-sure message-box-info" @click="toQuotationDetail()">
                            <div class="msg">
                                <p>我来询价啦！{{item.dep}}-{{item.des}}</p>
                                <div class="num" v-if="item.volume || !!item.gross_weight || (!!item.piece && item.piece!=0 )||!!item.deliver_date">
                                    <img src="https://doc.yctop.com/weapp/zjhy/static/2021-2-10/feedback.png" class="img" alt="">
                                    <div class="kgv">
                                        <p v-if="!!item.volume">{{item.volume}}m³</p>
                                        <i class="line" v-if="!!item.volume && (!!item.gross_weight ||!!item.piece && item.piece!=0 ||!!item.deliver_date)"></i>
                                        <p v-if="!!item.gross_weight">{{item.gross_weight}}KG</p>
                                        <i class="line" v-if="!!item.gross_weight && (!!item.piece && item.piece!=0 ||!!item.deliver_date)"></i>
                                        <p v-if="!!item.piece && item.piece!=0">{{item.piece}}件</p>
                                        <i class="line" v-if=" !!item.piece && item.piece!=0 && !!item.deliver_date"></i>
                                        <p>{{item.deliver_date}}</p>
                                    </div>
                                    
                                </div>
                            </div>
                            <div class="triangle-border sure-border"></div>
                            <div class="triangle-border sure-background"></div>
                        </div> 
                    </div>
                </div>
                <div class="item-box" v-else>
                    <p class="feedback-time">{{item.createdTime}}</p>
                    <div class="item item-right">
                        <van-image
                            round
                            width="0.8rem"
                            height="0.8rem"
                            :src="wx_usr.headImgUrl"
                            class="item-img"
                            @click="gotoMyPage()"
                        />
                        <div class="box">
                            <P class="readed" v-if="item.read">已读</P>
                            <div class="message-box message-box-right">
                                <span>{{item.msg}}</span>
                                <div class="triangle-border right-border"></div>
                                <div class="triangle-border tb-background"></div>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="item.type==1">
                <div class="item-box" v-if="!!item.dep">
                    <p class="feedback-time">{{item.createdTime}}</p>
                    <div class="item item-left">
                        <van-image
                            round
                            width="0.8rem"
                            height="0.8rem"
                            :src="!!agentInfo.avatar ? 'http://' + agentInfo.avatar :'https://doc.yctop.com/weapp/zjhy/static/2020-12-15/default-logo.png'"
                            class="item-img"
                            @click="gotoShop()"
                        />
                        <div class="message-box message-box-info" @click="toQuotationDetail()">
                            <div class="msg">
                                <p>{{item.dep}}-{{item.des}} 报价，快来查看吧！</p>
                                <div class="num">
                                    <img src="https://doc.yctop.com/weapp/zjhy/static/2021-2-10/feedback.png" class="img" alt="">
                                    <div class="kgv">
                                        <p>{{item.volume}}m³</p>
                                        <i class="line"></i>
                                        <p>{{item.gross_weight}}KG</p>
                                        <i class="line"></i>
                                        <p>{{item.piece}}件</p>
                                        <i class="line"></i>
                                        <p>{{item.deliver_date}}</p>
                                    </div>
                                    
                                </div>
                            </div>
                            <div class="triangle-border triangle-border-left left-border"></div>
                            <div class="triangle-border triangle-border-left left-background"></div>
                        </div>
                    
                    </div>
                </div>
                <div class="item-box" v-else>
                    <p class="feedback-time">{{item.createdTime}}</p>
                    <div class="item item-left">
                        <van-image
                            round
                            width="0.8rem"
                            height="0.8rem"
                            :src="!!agentInfo.avatar ? 'http://' + agentInfo.avatar :'https://doc.yctop.com/weapp/zjhy/static/2020-12-15/default-logo.png'"
                            class="item-img"
                            @click="gotoShop()"
                        />
                        <div class="box">
                            <div class="message-box">
                                <span>{{item.msg}}</span>
                                <div class="triangle-border triangle-border-left left-border"></div>
                                <div class="triangle-border triangle-border-left left-background"></div>
                            </div> 
                        </div>
                    </div>
                </div>
            
            </div>
        
        </div>
       
        <div class="item-box" v-if="!!quotationInfo && quotationInfo.status==1">
            <p class="feedback-time">{{SureTime}}</p>
            <p class="feedback-time check-time">已确认{{agentInfo.name}}的报价</p>
        </div>
        <div class="footer-int">
            <van-field v-model="value" placeholder="发消息…" center/>
            <van-button type="primary" :class="!!value? 'int-btn has-value':'int-btn'" @click="sendMsg" :loading="sendLoading">发送</van-button>
        </div>
	</div>
	
</template>
<script>
/* eslint-disable */
	import { mapState, mapGetters } from 'vuex';
	import { Dialog } from 'vant';
	import  REQ_HANDLE from '@/utils/request.js'
	export default {
		data: ()=> ({
			favorList:[],
			finished: false,
			pageIdx: 1,
            cnt:0,
            value:'',
            chatMessageList:[],
            sendLoading:false,
            agentInfo: {
                avatar: '',
                name:'',
			},
            preOrderInfo:{},
            preOrderId:'',
            quotationInfo:null,
            SureTime:'',
            scrollHeight: 0,
            cntTotal:0,
            moreLoading:false,
            preOrderDeliverDate:'',
		}),
		computed: {
			...mapState({
				usr: state => state.usr,
				token: state => state.token,
				wx_usr: state => state.wx_usr,
			}),
			...mapGetters({
				login: 'checkLogin',
			})
		},
		created: function() {
            
		},
		mounted: async function() {
            this.shopId=this.$route.params.shopId
            this.preOrderId=this.$route.params.preOrderId
            !!this.shopId && (this.agentInfo = await this.$store.dispatch('getAgentInfo',{shopId:this.shopId}) )
            !!this.preOrderId && await this.loadPreOrderDetail()
            let title= !!this.agentInfo && !!this.agentInfo.name? this.agentInfo.name:'指尖货运小店'
            this.$store.commit('updatePageTitle',{title:this.agentInfo.name})
            this.scrollBottom() 
        },
		methods: {
            scrollBottom: function(){
                const feedback = this.$refs.feedback;
                if(feedback.scrollHeight>this.getClientHeight()){
                    setTimeout(() => {
                        this.scrollHeight = feedback.scrollHeight;
                        window.scrollTo(0, this.scrollHeight);
                    }, 10);
                    this.indexSroll()
                }
            },
			sendMsg: async function(){
                this.sendLoading=true
                let url = '/api-mall/pre-order-msg/create/custom',
					params={
                        pre_order_id:this.preOrderId,
                        msg: this.value,
                    },
                    _this=this,
                    res = await REQ_HANDLE.agent.$post(url, params)
				if(!!res){
                    _this.sendLoading=false
                    await _this.getChatList()
                    _this.scrollBottom()
                    _this.value=''
				}else{
                    _this.sendLoading=false
                }
            },
            getChatList: async function(type){
                if (type=='more' && this.finished==true) {
                    return;
                };
                this.pageIdx= type=='more'? parseInt(Number(this.pageIdx)+1): 1
                let url = '/api-mall/pre-order-msg/list/custom',
					params={
                        pre_order_id:this.preOrderId,
                        pageIdx: this.pageIdx,
                    },
                    _this=this,
                    res = await REQ_HANDLE.agent.$get(url, params)
                
				if(!!res){
                    _this.cntTotal=res.data.cnt
                    let date1= !!_this.quotationInfo ? _this.quotationInfo.deliver_date.slice(5) :'',
                        newDate1=!!date1 ? date1.replace(/\./g, '-'):''
                    let date2= !!_this.preOrderInfo ? _this.preOrderInfo.deliver_date.slice(5) :'',
                        newDate2=!!date2 ? date2.replace(/\./g, '-'):'',
                        item= {
                            type:1,
                            dep: !!_this.quotationInfo ? _this.quotationInfo.dep:'',
                            des: !!_this.quotationInfo ? _this.quotationInfo.des:'',
                            volume: !!_this.quotationInfo ? _this.quotationInfo.volume:'',
                            gross_weight: !!_this.quotationInfo ? _this.quotationInfo.gross_weight:'',
                            piece: !!_this.quotationInfo ? _this.quotationInfo.piece:'',
                            created_at: !!_this.quotationInfo ? _this.quotationInfo.created_at:'',
                            deliver_date: newDate1,
                        }
                    let messageList=[
                        {
                            type:2,
                            dep: _this.preOrderInfo.dep,
                            des:_this.preOrderInfo.des,
                            volume: _this.preOrderInfo.volume,
                            gross_weight:_this.preOrderInfo.gross_weight,
                            piece:_this.preOrderInfo.piece,
                            created_at:_this.preOrderInfo.created_at,
                            deliver_date: newDate2,
                        }
                    ]
                    
                    if(type=='more'){
                         _this.chatMessageList=_this.chatMessageList.concat(res.data.result)
                    }else{
                        _this.chatMessageList=[...messageList,...res.data.result]
                        !!_this.quotationInfo && _this.chatMessageList.push(item)
                    }
                    _this.chatMessageList.sort(function(a,b){
                        return a.created_at > b.created_at ? 1 : -1
                    });
                    for(let item of _this.chatMessageList){
                        item.createdTime=item.created_at.slice(0,4)+'年'+item.created_at.slice(5,7)+'月'+item.created_at.slice(8,10)+'日'+' '+item.created_at.slice(-8,-3)
                    }
                    let finished=!!_this.quotationInfo && !!_this.chatMessageList.length && parseInt(_this.chatMessageList.length-2) >=_this.cntTotal || _this.quotationInfo==null && !!_this.chatMessageList.length && parseInt(_this.chatMessageList.length-1) >=_this.cntTotal
                    _this.finished =finished
				}
            },
            loadPreOrderDetail: async function() {
				let url = '/api-mall/quotation/'+this.preOrderId,
					res = await REQ_HANDLE.agent.$get(url),
					that=this
				if(!!res) {
                    that.preOrderInfo=res.data.preOrder
                    that.quotationInfo = !!res.data.quotation ? Object.assign({}, that.quotationInfo, res.data.quotation) :null
                    that.SureTime= !!that.quotationInfo ? that.quotationInfo.updated_at.slice(0,4)+'年'+that.quotationInfo.updated_at.slice(5,7)+'月'+that.quotationInfo.updated_at.slice(8,10)+'日'+' '+that.quotationInfo.updated_at.slice(-8,-3):'' 
                    that.preOrderDeliverDate=!!that.preOrderInfo ? that.preOrderInfo.deliver_date.slice(5) :''
                   
                    await that.getChatList()
                }
            },
            toQuotationDetail:function(){
				this.$router.push('/quotation/'+this.shopId+'/'+ this.preOrderId)
            },
            gotoShop: function(){
				this.$router.push('/shop/'+this.shopId)
            },
            gotoMyPage: function(){
				this.$router.push('/my')
            },
             //获取滚动条当前的位置
            getScrollTop: function() {
                var scrollTop = 0;
                if (document.documentElement && document.documentElement.scrollTop) {
                    scrollTop = document.documentElement.scrollTop;
                } else if (document.body) {
                    scrollTop = document.body.scrollTop;
                }
                return scrollTop;
            },

            //获取当前可是范围的高度
            getClientHeight: function() {
                var clientHeight = 0;
                if (document.body.clientHeight && document.documentElement.clientHeight) {
                    clientHeight = Math.min(document.body.clientHeight, document.documentElement.clientHeight);
                }
                else {
                    clientHeight = Math.max(document.body.clientHeight, document.documentElement.clientHeight);
                }
                return clientHeight;
            },
            //获取文档完整的高度
            getScrollHeight: function() {
                return Math.max(document.body.scrollHeight, document.documentElement.scrollHeight);
            },
            //滚动加载
            indexSroll: function(){
                let that=this
                document.addEventListener('scroll', ()=>{
                    if (that.finished==true) {
                        return;
                    };
                    setTimeout(async () => {
                        if (that.finished==true) {
                            return;
                        }
                        let ScrollTop=that.getScrollTop()
                        if (ScrollTop==0 && !that.moreLoading && that.finished==false) {
                            that.moreLoading=true
                            await that.getChatList('more')
                            that.moreLoading = false;
                        }
                    }, 1000);
                   
                })
            },
		}
			
	};
</script>
<style scoped >
@import "~@/assets/css/feedback.css";
</style>